import {iconCheckbox, iconCroix} from './our-qualities-svg';

export const ourQualities = document.querySelector('.our-qualities-container');

const fourthSection = `<h2 class="title-four-section">Nos principes :</h2>
<section class="container-four">
  <article class="article-four-section border">
    <article class="second-article-section">
      <span class="icone-checkbox">
        ${iconCheckbox}
      </span>
      <p>un travail de qualité</p>
    </article>
    <article class="second-article-section">
      <span class="icone-checkbox">
        ${iconCheckbox}
      </span>
      <p>un développeur de qualité</p>
    </article>
    <article class="second-article-section">
      <span class="icone-checkbox">
        ${iconCheckbox}
      </span>
      <p>un professionnel à l'écoute</p>
    </article>
    <article class="second-article-section">
      <span class="icone-checkbox">
        ${iconCheckbox}
      </span>
      <p>des sites personnalisés avec un design exceptionnel</p>
    </article>
    <article class="second-article-section">
      <span class="icone-checkbox">
        ${iconCheckbox}
      </span>
      <p>
        une adapatation responsive à tous les formats (ordinateurs,
        mobiles, tablettes)
      </p>
    </article>
    <article class="second-article-section">
      <span class="icone-checkbox">
        ${iconCheckbox}
      </span>
      <p>les meilleurs collaborateurs</p>
    </article>
  </article>
  <article class="article-four-section">
    <article class="second-article-section">
      <span class="icone-checkbox">
        ${iconCroix}
      </span>
      <p>un travail précipité</p>
    </article>
    <article class="second-article-section">
      <span class="icone-checkbox">
        ${iconCroix}
      </span>
      <p>un développeur approximatif</p>
    </article>
    <article class="second-article-section">
      <span class="icone-checkbox">
        ${iconCroix}
      </span>
      <p>un travail standardisé</p>
    </article>
    <article class="second-article-section">
      <span class="icone-checkbox">
        ${iconCroix}
      </span>
      <p>un travail standardisé</p>
    </article>
  </article>
</section>
<div class="btn-devis">
  <a href="/contact.html">
    <input
      type="button"
      value="Je demande mon devis"
      class="btn-input-devis"
    />
  </a>
</div>`;

ourQualities.innerHTML = fourthSection;