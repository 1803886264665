import {
  js,
  ts,
  next,
  react,
  strapi,
  nodeJs,
  php,
  laravel,
  sqlite,
  mysql,
  mongoDb,
  linux
} from '../svg/svgLanguages';

export const stackSection = document.querySelector('.stack-container');

const fifthSection = `<h2 id="competences" class="title-five-section">
Les principales technologies que l'on utilise
</h2>
<section>
<article class="container-five">
  <figure class="logoSectionFive">
    ${js}
  </figure>
  <figure class="logoSectionFive">
    ${ts}
  </figure>
  <figure class="logoSectionFive">
    ${next}
  </figure>
  <figure class="logoSectionFive">
    ${react}
  </figure>
  <figure class="logoSectionFive">
    ${strapi}
  </figure>
  <figure class="logoSectionFive">
    ${nodeJs}
  </figure>
  <figure class="logoSectionFive">
    ${php}
  </figure>
  <figure class="logoSectionFive">
    ${laravel}
  </figure>
  <figure class="logoSectionFive">
    ${sqlite}
  </figure>
  <figure class="logoSectionFive">
    ${mysql}
  </figure>
  <figure class="logoSectionFive">
    ${mongoDb}
  </figure>
  <figure class="logoSectionFive">
    ${linux}
  </figure>
</article>
</section>`;

stackSection.innerHTML = fifthSection;