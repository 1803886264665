export const prices = document.querySelector('.prices-container')
const seventhSection = `
<p id="tarifs" class="title-section-seven">Des tarifs abordables et selon vos besoins</p>
<p class="title-section-seven last-para-seven">Les packages pour une visibilité sur internet</p>
<section class="container-seven-one">
  <section class="ul-packages">
    <article class="red">
      <div>
        <p class="title-package">Le package Starter</p>
        <p class="tarif-package">350 euros</p>
      </div>
      <ul class="para-details">
        <li>- Site Vitrine</li>
        <li>- 1 page</li>
        <li>- Mise en ligne</li>
        <li>- Design personnalisé</li>
        <li>- Domaine sécurisé</li>
      </ul>
    </article>
  </section>
  <section class="ul-packages">
    <article class="red">
      <div>
        <p class="title-package">Le package Décollage</p>
        <p class="tarif-package">690 euros</p>
      </div>
      <ul class="para-details">
        <li>- L'ensemble du package Starter</li>
        <li>- Site Vitrine, blog, portfolio</li>
        <li>- Jusqu'à 3 pages</li>
      </ul>
    </article>
  </section>
  <section class="ul-packages">
    <article class="red">
      <div>
        <p class="title-package">Le package Fonctionnalités</p>
        <p class="tarif-package">990 euros</p>
      </div>
      <ul class="para-details">
        <li>- L'ensemble du package Décollage</li>
        <li>- Chat en ligne</li>
        <li>- Envoi de mails depuis votre site sur votre boite mail</li>
      </ul>
    </article>
  </section>
</section>
<p class="title-section-seven last-para-seven">Les packages pour développer son entreprise en ligne</p>
<section class="container-seven-one">
  <section class="ul-packages">
    <article class="red">
      <div>
        <p class="title-package">Le package e-Gestion</p>
        <p class="tarif-package">1490 euros</p>
      </div>
      <ul class="para-details">
        <li>- Site de gestion</li>
        <li>- Interface utilisateur</li>
        <li>- Fonctionnalités personnalisées</li>
      </ul>
    </article>
  </section>
  <section class="ul-packages">
    <article class="red">
      <div>
        <p class="title-package">Le package e-Commerce</p>
        <p class="tarif-package">À partir de 1890 euros</p>
      </div>
      <ul class="para-details">
        <li>- Site e-Commerce</li>
        <li>- Interface administrateur</li>
        <li>- Paiement en ligne</li>
        <li>- Sécurité avancée</li>
        <li></li>
      </ul>
    </article>
  </section>
  <section class="ul-packages">
    <article class="red">
      <div>
        <p class="title-package">Le package e-Liberté</p>
        <p class="tarif-package">Uniquement sur devis</p>
      </div>
      <ul class="para-details">
        <li>- Mise en place du site internet de votre choix avec les fonctionnalités de votre choix</li>
      </ul>
    </article>
  </section>
</section>
<p class="title-section-seven last-para-seven">Nos side services</p>
<section class="container-seven-one last-container-seven">
  <section class="ul-packages">
    <article class="red">
      <div>
        <p class="title-package">Package Réseaux sociaux</p>
        <p class="tarif-package">300 euros/mois</p>
      </div>
      <ul class="para-details">
        <li>- Création de 2 réseaux sociaux aux choix</li>
        <li>- 3 publications/semaine</li>
        <li>- Modérations des commentaires</li>
      </ul>
    </article>
  </section>
  <section class="ul-packages">
    <article class="red">
      <div>
        <p class="title-package">Package Refonte</p>
        <p class="tarif-package">Sur devis</p>
      </div>
      <ul class="para-details">
        <li>- Refonte du design du site de votre choix</li>
      </ul>
    </article>
  </section>
  <section class="ul-packages">
    <article class="red">
      <div>
        <p class="title-package">Package Conseil</p>
        <p class="tarif-package">25 euros/heure</p>
      </div>
      <ul class="para-details">
        <li>- Conseils et orientations techniques pour faire les meilleurs choix</li>
      </ul>
    </article>
  </section>
</section>
<p class="contact-section-seven last-para-seven">Contactez-Nous et obtenez votre devis rapidement</p>
<p class="last-title-section-seven">Notre objectif ? Vous satisfaire et vous accompagner pour répondre à votre besoin personnalisé</p>
<div class="button-contact-us">
  <a href="/contact.html">
    <input class="contact-button-last" type="button" value="Contactez-Nous">
  </a>
</div>
`

prices.innerHTML = seventhSection