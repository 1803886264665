import {vectorWebDevice, vectorShopping, vectorPayment} from '../svg/svgVector';

export const statistics = document.querySelector('.statistics-container');
const thirdSection = `<p class="title-three-section">
Pourquoi avoir une présence digitale sur le web ?
</p>

<article class="article-container-three separate-line-third-section">
<h2 class="title-article-three">
  Plus de 70% des français se connectent sur internet
</h2>
<figure>
  ${vectorWebDevice}
</figure>
</article>
<article class="article-container-three separate-line-third-section">
<figure>
  ${vectorShopping}
</figure>
<h2 class="title-article-three">
  Plus de 80% des français font leur achat en ligne, soit plus de 37
  millions des français
</h2>
</article>
<article class="article-container-three">
<h2 class="title-article-three">
  En France, la vente en ligne a atteint plus de 100 milliards d'euros
  en 2020
</h2>
<figure>
  ${vectorPayment}
</figure>
</article>`;

statistics.innerHTML = thirdSection;