import {
  commad,
  balqys,
  centreMoumtez,
  softHouse,
  fabrikBurger,
  oclassico,
  ladress
} from '../svg/svgTravail';

export const ourRealisations = document.querySelector('.our-realisations-container');
const sixthSection = `
<h2 id="realisations" class="title-six-section">
  Ils nous ont fait confiance
</h2>
<article class="first-container-six">
  <figure>
    ${commad}
  </figure>
  <figure>
    ${balqys}
  </figure>
  <figure>
    ${centreMoumtez}
  </figure>
  <figure>
    ${softHouse}
  </figure>
  </article>
<article class="second-container-six">
  <figure>
    ${fabrikBurger}
  </figure>
  <figure>
    ${oclassico}
  </figure>
</article>
<section>
  <article class="three-container-six">
    <figure>
      ${ladress}
    </figure>
  </article>
</section>
`;

ourRealisations.innerHTML = sixthSection;