import {phoneMobile, whatsApp, envelopMail} from './svg-section-bottom';

export const sectionBottom = document.querySelector('.section-bottom');

const bottomElmt = `<section class="container-others-contact">
<h2 class="others-contact-title">
  Les autres moyens pour nous contacter:
</h2>
<ul class="others-contact">
  <a class="others-contact-link" href="#">
    <span class="icon-sociaux-contact">
      ${phoneMobile}
    </span>
    <span class="text-contact-link">
      <li>Téléphone</li>
    </span>
  </a>
  <a class="others-contact-link" href="#">
    <span class="icon-sociaux-contact">
      ${whatsApp}
    </span>
    <span class="text-contact-link">
      <li>WhatsApp</li>
    </span>
  </a>
  <a class="others-contact-link" href="#">
    <span class="icon-sociaux-contact">
      ${envelopMail}
    </span>
    <span class="text-contact-link">
      <li>Mail</li>
    </span>
  </a>
</ul>
</section>
`;

sectionBottom.innerHTML = bottomElmt;