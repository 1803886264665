export const btnContactForm = document.getElementById("submitContactForm");
// btnContactForm.addEventListener("click", myFunction);
// function myFunction (e) {

//   e.preventDefault();

//   let name = document.getElementById("name").value
//   let prenom = document.getElementById("prenom").value
//   let email = document.getElementById("email").value
//   let message = document.getElementById("message").value
//   console.log(name)
//   console.log(prenom)
//   console.log(email)
//   console.log(message)
// }
// btnContactForm.addEventListener('submit', e => {
  // el/movuja -- suitsconception@gmail.com
//   e.preventDefault();
//   const formData = new FormData(form);
//   const infos = Object.fromEntries(formData.entries());

//   fetch('https://formsubmit.co/el/movuja', {
//     method: 'POST',
//     headers: {
//       'Content-Type': 'application/json',
//       'Accept': 'application/json'
//     },
//     body: JSON.stringify({
//       Nom: infos.lastname,
//       Prenom: infos.firstname,
//       Email: infos.email,
//       Message: infos.message
//     })
//   })
//       .then(response => response.json())
//       .then(
//           data => {
//             console.log(data)
//               if (data.success === 'true') {
//                   btnContactForm.style.display = 'none';
//                   btnContactForm.insertAdjacentHTML('afterend', '<div class="confirmation">' +
//                       '<p class="msg-confirmation">Votre mail a bien été envoyé, vous serez recontacté rapidement.</p>' +
//                       '<img class="img-confirmation" src="https://res.cloudinary.com/dps3eww2i/image/upload/v1635669511/commad/logo.png" alt="logo-commad">' +
//                       '</div>');
//                     } else {
//                   btnContactForm.style.display = 'none';
//                   btnContactForm.insertAdjacentHTML('afterend', '<div class="confirmation">' +
//                       '<p class="">Oops il y eut un problème concernant l&rsquo;envoi de votre mail.</p>' +
//                       '<img class="img-confirmation" src="https://res.cloudinary.com/dps3eww2i/image/upload/v1635669511/commad/logo.png" alt="logo-commad">' +
//                       '</div>');
//                     }
//                   })
//       .catch(error => console.log(error));
//       console.log('form ',infos)
//  });
