import {srcImg} from './svg/logo';

export const headerElmt = document.querySelector('.header');
let navBar = `<nav class="navbar-home">
<figure class="logo-navbar">
  <a href="/">
    ${srcImg}
  </a>
</figure>
<div class="btn11">
  <div class="ligneUnique"></div> 
</div>
<section class="main-navbar">
  <ul class="main-one">
    <li><a href="/#tarifs">Tarifs</a></li>
    <li><a href="/#realisations">Réalisations</a></li>
    <li><a href="/#competences">Compétences</a></li>
    <li><a href="/contact.html">Nous contacter</a></li>
  </ul>
</section>
</nav>`;

headerElmt.innerHTML = navBar;
