import {srcImg} from './svg/logo';
import {srcCalender, srcPhone, srcEnvelop, srcInsta, srcTwitter, srcSnapchat} from './svg/svgIcon';

export const footer = document.querySelector('.footer');

let navBarFooter = `<section class="container">
<figure class="logo-navbar">
  <a href="/"
    >${srcImg}</a>
</figure>
<article>
  <h3 class="footer-title">Suits Conception</h3>
  <ul class="footer-section-link">
    <a class="footer-contact-link" href="/contact.html">
      <span class="icon-sociaux-contact">
        ${srcCalender}
      </span>
      <span class="text-contact-link">
        <li>Prendre rendez-vous</li>
      </span>
    </a>
    <a class="footer-contact-link" href="/contact">
      <span class="icon-sociaux-contact">
        ${srcPhone}
      </span>
      <span class="text-contact-link">
        <li>0774362696</li>
      </span>
    </a>
    <a
      class="footer-contact-link"
      href="mailto:suitsconception@gmail.com"
    >
      <span class="icon-sociaux-contact">
        ${srcEnvelop}
      </span>
      <span class="text-contact-link">
        <li>suitsconception@gmail.com</li>
      </span>
    </a>
  </ul>
</article>
<article>
  <h3 class="footer-title">À propos</h3>
  <ul class="footer-section-link-last">
    <a class="footer-contact-link" href="#competences">
      <span class="text-contact-link">
        <li>Compétences</li>
      </span>
    </a>
    <a class="footer-contact-link" href="#realisations">
      <span class="text-contact-link">
        <li>Réalisations</li>
      </span>
    </a>
    <a class="footer-contact-link" href="#tarifs">
      <span class="text-contact-link">
        <li>Tarifs</li>
      </span>
    </a>
    <a class="footer-contact-link" href="/contact.html">
      <span class="text-contact-link">
        <li>Qui sommes nous</li>
      </span>
    </a>
  </ul>
</article>
<article>
  <h3 class="footer-title">Nous suivre</h3>
  <ul class="footer-section-link">
    <a class="footer-contact-link" href="#">
      <span class="icon-sociaux-footer">
        ${srcInsta}
      </span>
      <span class="text-contact-link">
        <li>Instagram</li>
      </span>
    </a>
    <a class="footer-contact-link" href="#">
      <span class="icon-sociaux-footer">
        ${srcTwitter}
      </span>
      <span class="text-contact-link">
        <li>Twitter</li>
      </span>
    </a>
    <a class="footer-contact-link" href="#">
      <span class="icon-sociaux-footer">
        ${srcSnapchat}
      </span>
      <span class="text-contact-link">
        <li>Snapchat</li>
      </span>
    </a>
  </ul>
</article>
</section>`;

footer.innerHTML = navBarFooter;
