import {vectorOneSection} from '../svg/svgVector';

export const introduction = document.querySelector('.introduction-container');

const introductionElmt = `
<article class="article-container">
  <h2 class="title-first-section">
    Créer votre site web avec Suits Conception
  </h2>
  <a href="/contact.html">
    <input
      type="button"
      value="Prendre contact"
      class="btn-input-top first-input"
    />
  </a>
  <a href="#tarifs">
    <input
      type="button"
      value="Voir les tarifs"
      class="btn-input-top second-input"
    />
  </a>
</article>
<figure class="vectorOne">
  ${vectorOneSection}
</figure>
`;

introduction.innerHTML = introductionElmt;