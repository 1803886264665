import {vectorQuestion, vectorVoting} from '../svg/svgVector';

export const whyChooseUs = document.querySelector('.why-choose-us-container');
const secondSection = `<article class="first-party">
<figure class="vector-secondSection">
  ${vectorQuestion}
</figure>
<h2 class="first-party-h2">Pourquoi choisir Suits Conception ?</h2>
<figure class="vector-secondSection">
  ${vectorVoting}
</figure>
</article>
<p class="first-para">
Choisir de creér son site web avec Suits Conception c'est choisir un
site professionnel performant avec un beau design, un accompagnement
humain et professionel à l'écoute de votre besoin pour y répondre en
vous apportant la meilleure solution.
</p>`;

whyChooseUs.innerHTML = secondSection;